import React, {useCallback, useContext, useEffect, useMemo, useState} from "react"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import useDetectMobileScreen from "../../util/hooks/useDetectMobileScreen";
import QueueItem from "./QueueItem";
import {MediaQueueWrapperContext} from "./state/MediaQueueWrapper";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import {COLLAPSE_ICON, EXPAND_ICON, logger} from "../../util/Util";
import {useStateWithSessionStorage} from "../../util/hooks/useSessionStorage";
import { ReactSortable } from "react-sortablejs";
import ListGroup from "react-bootstrap/ListGroup";
import {List} from "semantic-ui-react";
import {useLocation} from "@reach/router";


export default function QueueUpNext() {
    let queueContext = useContext(MediaQueueWrapperContext)
    let queueData = queueContext.data;
    let location = useLocation()
    let {isMobile} = useDetectMobileScreen();
    let [upNextCollapsed, setQueueCollapsed] = useStateWithSessionStorage("collapse_queue", false);

    let [href, setHref] = useStateWithSessionStorage("href-storage-key", null)
    let className = useMemo(() => {
        let htmlClass = "visible_up_next";
        if (!isMobile) {
            htmlClass += " queue_up_next_desktop"
        }
        return htmlClass;
    }, [isMobile])

    // useEffect(() => {
    //     logger(location.href)
    //
    //     if (location.href !== href) {
    //         setHref(location.href)
    //         queueContext.setVisibility(false)
    //     }
    //
    // }, [location, queueContext, href])

    let header = (
        <Container>
            <Row style={{justifyContent: "space-between"}}>
                <div>
                    <Button variant={upNextCollapsed ? "primary" : "secondary"} onClick={() => setQueueCollapsed(!upNextCollapsed)}>
                        {upNextCollapsed ? (EXPAND_ICON) : (COLLAPSE_ICON)}
                    </Button>

                    <b>Queue</b> ({queueData.playIndex+1}/{queueData.queue.length})
                </div>
                <div>
                    <Button variant={"danger"} onClick={queueContext.clearQueue}>Clear Queue</Button>
                </div>
            </Row>
        </Container>
    )
    let body = isMobile ? (
        <>
            <List className={"queue_list"}>
                {queueData.queue.map((media, index) => {
                    return (
                        <QueueItem media={media} key={index} index={index}/>
                    )
                })}
            </List>
        </>
        ) : (
        <>
        <List className={"queue_list"}>
            <ReactSortable group={"queue_group"} animation={400}
                           list={queueData.queue} setList={queueContext.reorderQueue}>
                {queueData.queue.map((media, index) => {
                    return (
                        <div key={media.src}>
                            <QueueItem media={media} key={index} index={index}/>
                        </div>
                    )
                })}
            </ReactSortable>
        </List>
        </>
    )
    return queueData.queue.length > 0 && (
        <Row id={"queue_up_next"}
             className={className}>
            <Col className={"queue_col"}>
                {/*<CollapsableCard header={header} body={body}>*/}
                {/*</CollapsableCard>*/}
                <Card>
                    <Card.Header>
                        {header}
                    </Card.Header>
                    {!upNextCollapsed &&
                        <Card.Body>
                            {body}
                        </Card.Body>
                    }
                </Card>
            </Col>
        </Row>
    )
}

