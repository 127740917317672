import React, {useContext, useMemo} from "react";
import RoundupLabel from "../roundup/ViewRoundup";
import Loading from "../Loading";
import CollapsableCard from "../CollapsableCard";
import {useStateWithSessionStorage} from "../../util/hooks/useSessionStorage";
import {SubredditConfigContext} from "../../util/context/SubredditConfigContext";
import Card from "react-bootstrap/Card";
import {Container, Row, Col} from "react-bootstrap";
import {VerifiedAccountsContext} from "../../util/context/VerifiedAccountsContext";
import Alert from "react-bootstrap/Alert";

export default function AllRoundups(props) {
    let {roundupData, isRoundupDataLoading} = useContext(SubredditConfigContext);
    let {userId, loggedIn} = useContext(VerifiedAccountsContext)

    let [filter, setFilter] = useStateWithSessionStorage("roundupfilters", "")
    let filtBack = (val) => {
        setFilter(val)
    }
    let filteredRoundups = useMemo(() => {
        let result = {}

        for (const [sub, metaList] of Object.entries(roundupData?.metaBySub) || []) {
            if (sub.includes(filter)) {
                // max of 4 roundups per sub
                result[sub] = metaList.slice(0, 4);
            }
        }
        return result;
    }, [filter, roundupData])

    let body = useMemo(() => {
        return <Container fluid>

            {!isRoundupDataLoading && roundupData.loadedMeta && !loggedIn &&  (
                <Row><Col className={"no_roundup_alert"}>
                    <Alert variant={"warning"} style={{width: "fit-content"}}>
                        <Alert.Heading>You are not logged in. Check out these example roundups:</Alert.Heading>
                    </Alert>
                    {/*<br/>*/}
                </Col></Row>
            )}
            <Row style={{rowGap: ".2rem"}}>
            {isRoundupDataLoading && !roundupData.loadedMeta &&  (
                // show the loading icon if we're waiting on the GET request & there are no sub configs
                // (ie: we didn't get anything from the session storage cache)
                <Loading/>
            )}
            {filteredRoundups && Object.keys(filteredRoundups).sort().map((subreddit) => {
                    let subRoundups = filteredRoundups[subreddit];
                    if (subRoundups && subRoundups.length) {
                        return (
                            <Col className={"meta_col"} key={subreddit} xs={6} sm={4}>
                                <Card className={"nested_card nested_card_flex meta_sub_card"}
                                      id={"roundup_sub_" + subreddit}>
                                    <Card.Header>
                                        <b>{subreddit}</b>
                                    </Card.Header>
                                    <Card.Body>
                                        <div>
                                            {subRoundups.map((roundup) => {
                                                return <RoundupLabel roundup={roundup} key={roundup.id}/>
                                            })}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    } else {
                        return null;
                    }
                }
            )}

            {roundupData.loadedMeta && Object.keys(roundupData.metaBySub).length === 0 && (
                <div>You don't have any roundups yet</div>
            )}
            </Row>
        </Container>
    }, [isRoundupDataLoading, filteredRoundups, roundupData.metaBySub, roundupData.loadedMeta, roundupData, loggedIn])

    return (<CollapsableCard filterCallback={filtBack} cardId={"roundup_container"} className={"top_level_card"}
                             header={<h4>View Roundups</h4>} body={body}
        />
    )

}