import Nav from "react-bootstrap/Nav";
import {Link} from "@reach/router";
import {BiErrorCircle, BiLogOut, BiMessageDots, BiLogIn} from "react-icons/bi";
import {CgProfile} from "react-icons/cg";
import {AiOutlineHome} from "react-icons/ai";
import Navbar from "react-bootstrap/Navbar";
import React, {useContext} from "react";
import Badge from "react-bootstrap/Badge";
import {HttpContext} from "../util/hooks/useHttp";
import Image from "react-bootstrap/cjs/Image";
import rr_icon from "../resources/rr-logo.png"
import Cookies from "universal-cookie";
import Container from "react-bootstrap/Container";
import {VerifiedAccountsContext} from "../util/context/VerifiedAccountsContext";
import {SubredditConfigContext} from "../util/context/SubredditConfigContext";

export default function MyNavBar(props) {
    const cookies = new Cookies();
    let httpContext = useContext(HttpContext);
    let {redditError, emailError} = useContext(SubredditConfigContext);
    let {loggedIn} = useContext(VerifiedAccountsContext);
    return (
        <Container>

            <Navbar expand={loggedIn ? "sm" : true} collapseOnSelect={true}>
                <div className={"navBarWrapper"}>
                    {/* LOGO */}
                    <Link to={"/"} id={"brand_link"}>
                        <Navbar.Brand>
                            <Image src={rr_icon}
                                   id={"brand_image"}
                                   width={100}
                                   height={50}
                                   alt={"site logo"}
                            />
                        </Navbar.Brand>
                    </Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav"/>
                    <Navbar.Collapse>
                        <Nav>
                            {/* Home */}
                            {loggedIn ?
                                <>
                                    <Nav.Item>
                                        <Link to={"/"} className={"nav-link"}>
                                            <AiOutlineHome/> Home
                                        </Link>
                                    </Nav.Item>

                                    <Nav.Item className={"nav-link nav-separator"}> | </Nav.Item>

                                    {/* Profile */}
                                    <Nav.Item>
                                        <Link to={"/profile"} className={"nav-link"}>
                                            <CgProfile/> Profile
                                            {(redditError || emailError) && (
                                                <span>
                                                &nbsp;
                                                    <Badge variant={"danger"}>
                                                    <BiErrorCircle color={"black"}/>
                                                </Badge>
                                            </span>
                                            )}
                                        </Link>
                                    </Nav.Item>


                                    <Nav.Item className={"nav-link nav-separator"}> | </Nav.Item>

                                    {/* Contact */}
                                    <Nav.Item>
                                        <Link to={"/about"} className={"nav-link"}>
                                            <BiMessageDots/> Feedback
                                        </Link>
                                    </Nav.Item>


                                    <Nav.Item className={"nav-link nav-separator"}> | </Nav.Item>

                                    {/* Logout */}
                                    <Nav.Item style={{cursor: "pointer"}} className={"nav-link"} onClick={() => {
                                        cookies.set("auth", "false", {maxAge: 0, path: "/"})
                                        httpContext.sendRequest("/logout", {method: "POST"})
                                            .then(() => {
                                                window.location.pathname = "/welcome";
                                            })
                                            .catch(() => {
                                                window.location.pathname = "/welcome";
                                            })
                                    }}>
                                        <BiLogOut/> Logout
                                    </Nav.Item>
                                </>
                             : (
                                <>
                                    <Nav.Item>
                                        <Link to={"/welcome/login"} className={"nav-link"}>
                                            <BiLogIn/>Login
                                        </Link>
                                    </Nav.Item>
                                </>

                                )}

                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        </Container>
    )
}