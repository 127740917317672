import {createContext} from "react";
import {cloneDeep} from "../Util";

export const VerifiedAccountsContext = createContext({});

export const ADD_SUBREDDITS = "ADD_SUBREDDITS";

export function subredditListReducer(existingSubreddits, action) {
    let copy = cloneDeep(existingSubreddits);
    switch (action.type) {
        case ADD_SUBREDDITS:
            let existingSubNames = copy.map((s) => s.name.toLowerCase())
            let newSubNames = []
            let noDupes = action.payload.filter((sub) => {
                let lowerName = sub.name.toLowerCase()
                let shouldInclude = !existingSubNames.includes(lowerName) && !newSubNames.includes(lowerName)
                newSubNames.push(lowerName)
                return shouldInclude
            })
            copy.push(...noDupes);
            break;
        default:
        //nothing
    }
    return copy;
}