import {HttpError} from "./HttpError";
import {logger} from "./Util";

export function logResponse(resp, options, path) {
    // logger(`[${options.method}] `, path, resp)
    return resp

}

export function logError(error) {
    // logger('There was a problem with an http request : \n', error.toString());
}

export async function validateResponse(response) {
    if (!response.ok) {
        let json;
        try {
            json = await response.json();
        } catch (e) {
            json = {}
        }
        throw new HttpError(response, json);
    }
    return response;
}

export function readResponseAsJSON(response) {
    if (response.status === 204) {
        logger("204 null")
        return null
    }

    let respBody;
    try {
        respBody = response.json();
    } catch (e) {
        respBody = response.body();
    }
    return respBody;
}
export function readResponseAsTxt(response) {
    if (response.status === 204) {
        logger("204 null")
        return null
    }

    let respBody;
    try {
        respBody = response.text();
    } catch (e) {
        respBody = response.body();
    }
    return respBody;
}

/**
 *
 * @param options
 * @param isLocal: True if this is a request to my server
 * @returns {{opt: any, params: any}}
 */
export function getRequestParams(options, isLocal) {
    const headers = new Headers();
    headers.set("Accept", "application/json")
    let defaultOptions = {
        body: null,
        signal: null,
        callback: (resp) => {
            return resp
        },
        errorCallback: (resp) => {
            throw resp
        },
        headers: headers,
        method: "GET"
    };

    // merge/overwrite defaultOptions by the given options
    let opt = Object.assign({}, defaultOptions, options);

    if (opt.bearer) {
        headers.set("Authorization", "Bearer " + options.bearer)
    }
    if (opt.method === "PUT" || opt.method === "POST" || opt.method === "DELETE") {
        if (isLocal) {
            headers.set("X-XSRF-TOKEN", options["xsrf"]);
        }
        headers.set("Content-Type", "application/json");
    }

    // merge the given options to create the params object. params always contains a rest method,
    // and optionally contains a post body, signal, and headers (signal is used to cancel the fetch request)
    let interruptSignal = opt.signal ? {signal: opt.signal} : {};
    if (opt.body && typeof opt.body === "object") {
        opt.body = JSON.stringify(opt.body)
    }
    let body = opt.body ? {body: opt.body} : {};
    let method = {method: opt.method}
    let finalHeaders = {headers: opt.headers}
    let params = Object.assign({}, method, interruptSignal, body, finalHeaders);
    return {params: params, opt: opt};
}

export function parseQuery(queryString) {
    // TODO this doesn't handle multiple values for the same parameter
    if (queryString === undefined) {
        queryString = window.location.search;
    }
    let query = {};
    let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
        let pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

