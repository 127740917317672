import React, {useContext} from "react";
import AddSubreddit from "../config/AddSubreddit";
import ExistingConfig from "../config/ExistingConfig";
import AllRoundups from "./AllRoundups";
import {VerifiedAccountsContext} from "../../util/context/VerifiedAccountsContext";

export default function HomePage(props) {
    let subConfigs = props.subConfigs;
    let {userId, loggedIn, displayName} = useContext(VerifiedAccountsContext)

    return (
        <div>
            {loggedIn && (
                <AddSubreddit subConfigs={subConfigs}/>
            )}
            <AllRoundups/>
            <ExistingConfig subConfigs={subConfigs}/>
        </div>
    )
}