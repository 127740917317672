import React, {useCallback, useContext, useMemo, useState} from "react";
import Button from "react-bootstrap/Button";
import {useNavigate} from "@reach/router";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/cjs/Col";
import {
    capitalizeString,
    DELIVER_BY_EMAIL,
    ERROR_TOAST, SUCCESS_TOAST,
    TIME_VALUES
} from "../../util/Util";
import {VerifiedAccountsContext} from "../../util/context/VerifiedAccountsContext";
import Card from "react-bootstrap/cjs/Card";
import Modal from "react-bootstrap/Modal";
import {HttpContext} from "../../util/hooks/useHttp";
import {SubredditConfigContext} from "../../util/context/SubredditConfigContext";
import {MyToastContext} from "../../util/context/MyToastContext";
import Loading from "../Loading";
import {hasRedditError} from "../../App";
import CollapsableCard from "../CollapsableCard";

export default function ExistingConfig(props) {
    let httpContext = useContext(HttpContext);
    let subConfigContext = useContext(SubredditConfigContext);
    let toastContext = useContext(MyToastContext);
    let accountsContext = useContext(VerifiedAccountsContext);

    let emailAccount = accountsContext.email;
    let redditAccount = accountsContext.reddit;
    let [showDeleteModal, setShowDeleteModal] = useState(false);
    let [configToDelete, setConfigToDelete] = useState({subreddit: ""});

    let subConfigs = props.subConfigs

    let nav = useNavigate();

    let [filter, setFilter] = useState("");
    let filterCallback = useCallback((newFilter)=> {
        setFilter(newFilter);
    }, [])

    let filteredConfigs = useMemo(() => {
        let filteredList = subConfigs;
        if (filter) {
            filteredList = filteredList.filter(sub => sub.subreddit.includes(filter))
        }
        return filteredList;
    }, [filter, subConfigs])


    function handleDeleteConfigModalClose(resp) {
        // TODO this code is duplicated search for handleDeleteConfigModalClose(
        let config = configToDelete;
        if (resp && resp.target.id === "modal-delete") {
            if (!config.id) {
                let sameSub = subConfigContext.subreddits.find((contextSub) => {
                    return contextSub.subreddit === config.subreddit;
                })
                if (sameSub) {
                    config.id = sameSub.id;
                }
            }
            if (config.id) {
                httpContext.sendRequest(`/api/userconfig/${config.id}`, "DELETE")
                    .then((resp) => {
                        if (resp && resp.success) {
                            let configId = resp.success;
                            subConfigContext.deleteConfig(configId);
                            toastContext.addToast("Successfully deleted configuration for " + config.subreddit, SUCCESS_TOAST);
                        } else {
                            toastContext.addToast("There was an error deleting your configuration.", ERROR_TOAST);
                        }
                    })
                    .catch((resp) => {
                        toastContext.addToast("There was an error deleting your configuration.", ERROR_TOAST);
                    });
            } else {
                toastContext.addToast("Successfully deleted config", SUCCESS_TOAST);
            }
        }
        setShowDeleteModal(false);
        setConfigToDelete({subreddit: ""});
    }

    let body = useMemo(() => {
        return <>
            {subConfigContext.isConfigLoading && subConfigs.length === 0 && (
                // show the loading icon if we're waiting on the GET request & there are no sub conigs
                // (ie: we didn't get anything from the session storage cache)
                <Loading/>
            )}
            {subConfigContext.isConfigLoaded && subConfigs.length === 0 && (
                <span>You don't have any configs</span>
            )}
            {filteredConfigs.map((config, index) => {
                let isUserLoaded = accountsContext.isUserLoaded
                let isEmail = config.deliveryMethod === DELIVER_BY_EMAIL

                // set the deliverTo string
                let deliverTo = config.deliveryMethod.toLowerCase()
                if (isEmail && emailAccount?.username) {
                    deliverTo = emailAccount.username;
                }
                if (!isEmail && redditAccount?.username) {
                    deliverTo = redditAccount.username;
                }

                let mailTime = TIME_VALUES[Number(config.mailTime)];
                let mailTimeString = config.mailDay === "daily" ?
                    <p><b>Every day</b> at <b>{mailTime}</b></p> :
                    <p>Every <b>{capitalizeString(config.mailDay)}</b> at <b>{mailTime}</b></p>
                return (
                    <Card key={index} className={"nested_card nested_card_flex config_card"}>
                        <Card.Header className={"config_card_header"}>
                            <b>
                                {config.subreddit}&nbsp;
                            </b>
                            <Button style={{marginRight: "15px"}}
                                    variant={"secondary"}
                                    onClick={() => nav("/editConfig", {state: {config: config}})}>
                                Edit
                            </Button>
                            <Button variant={"danger"}
                                    onClick={(e) => {
                                        setShowDeleteModal(true);
                                        setConfigToDelete(config);
                                    }}>
                                Delete
                            </Button>
                        </Card.Header>

                        <Card.Body>
                            <Container>
                                <Row>
                                    <Col className={"config_card_body"}>
                                        <span>
                                            {mailTimeString}
                                            Delivered to <b>{deliverTo}</b>
                                        </span>
                                    </Col>
                                </Row>
                                    {(subConfigContext.emailError || subConfigContext.redditError) &&
                                        <>
                                            <br/>
                                        <Row xs={1}>
                                            <Button variant={"warning"}
                                                    onClick={() => nav("/profile")}>
                                                Warning: roundups can not be sent yet. Click here for more
                                                details.</Button>
                                        </Row>
                                        </>
                                    }
                            </Container>
                        </Card.Body>
                    </Card>
                )
            })}
        </>
    }, [subConfigContext.isConfigLoading, subConfigContext.isConfigLoaded, subConfigs.length, filteredConfigs, accountsContext.isUserLoaded, emailAccount, redditAccount, nav])


    return (
        <div>
            <CollapsableCard filterCallback={filterCallback} cardId={"existing_configs"} body={body} className={"top_level_card"}
                             header={<h4>Edit Your Configs</h4>}
            />

            {/* Delete config modal */}
            <Modal show={showDeleteModal} centered={true} onHide={handleDeleteConfigModalClose}>
                <Modal.Title>Confirm Delete: {configToDelete.subreddit}</Modal.Title>
                <Modal.Body>
                    <p>
                        Are you sure you want to delete your roundup config for {configToDelete.subreddit}?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button id={"modal-cancel"} variant={"secondary"}
                            onClick={handleDeleteConfigModalClose}>Cancel</Button>
                    <Button id={"modal-delete"} variant={"danger"}
                            onClick={handleDeleteConfigModalClose}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}