import React, {useCallback, useContext, useMemo, useState} from 'react'
import {Button} from "react-bootstrap";
import {BsFileText, BsFillImageFill, BsLink} from "react-icons/bs";
import {FaPlay} from "react-icons/fa";
import parse from "html-react-parser";
import {cloneDeep, queueIcon} from "../../util/Util";
import Image from "react-bootstrap/Image";
import Anchor from "../Anchor";
import RedditMarkdown from "./RedditMarkdown";
import FlairAndTitle from "./FlairAndTitle";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import useDetectMobileScreen, {SM} from "../../util/hooks/useDetectMobileScreen"
import RedditLink from "./RedditLink";
import {getSiteFromUrl, getSiteIconForLink,} from "../media_queue/util/SiteIcons";
import MusicMirrors from "../media_queue/util/MusicMirrors";
import {MediaQueueWrapperContext} from "../media_queue/state/MediaQueueWrapper";
import {
    embeddableNoMirrorsPost, isApprovedSitePost,
    isImagePost, isRedditGalleryPost, isVideoPost, showExpandoButtonPost
} from "../../util/context/SubredditConfigContext";
import ScriptTag from "react-script-tag";
import ResizableImage from "./ResizableImage";
import {RoundupMatcherContext} from "./RoundupMatcher";
import {useStateWithSessionStorage} from "../../util/hooks/useSessionStorage";
import EmbeddedRedditPost from "./EmbeddedRedditPost";

/**
 * Returns a valid url for a reddit permalink.
 * Sometimes the permalink of a post is a absolute url, sometimes it is a relative url
 * @param permalink
 */
export function getRedditPermalink(permalink) {
    return permalink.startsWith("/r/") ? `https://reddit.com${permalink}` : permalink
}

export function isSelfPost(post) {
    return (post?.url && post.url.includes(post.permalink))
}

export function isCanonLink(mirrorData, post) {
    let site1 = getSiteFromUrl(post.url)
    let site2 = getSiteFromUrl(mirrorData.otherLink)
    return site1 === site2 && site1 != null && site2 != null;
}

/**
 * Display a reddit post or comment
 * @param props
 * @constructor
 */
export default function RedditPost(props) {
    let queueContext = useContext(MediaQueueWrapperContext);

    let {addEmbeddableToQueue, expandAll, collapseAll} = useContext(RoundupMatcherContext);

    let {screenWidth, breakpoint} = useDetectMobileScreen();
    let post = props.post

    let [buttonClicked, setButtonClicked] = useStateWithSessionStorage(post.id + "_expand",
        {time: null, prevState: isSelfPost(post)});

    let embeddableNoMirrors = useMemo(() => {
        return embeddableNoMirrorsPost(post)
    }, [post])

    let isImage = useMemo(() => {
        return isImagePost(post)
    }, [post])

    let isVideo = useMemo(() => {
        return isVideoPost(post)
    }, [post])

    let isRedditGallery = useMemo(() => {
        return isRedditGalleryPost(post)
    }, [post])

    /**
     * For self-posts and image posts
     */
    let showExpandoButton = useMemo(() => {
        return showExpandoButtonPost(post)
    }, [post]);

    let isExpanded = useMemo(() => {
        let thisButtonTime = buttonClicked.time
        let prevState = buttonClicked.prevState
        let mostRecent = Math.max(thisButtonTime, expandAll, collapseAll)
        if (mostRecent === collapseAll && collapseAll) {
            return false
        } else if (mostRecent === expandAll && expandAll) {
            return true
        } else if (mostRecent === thisButtonTime && thisButtonTime) {
            return !prevState;
        }
        return prevState
    }, [post, expandAll, collapseAll, buttonClicked])


    let thumbnail = useMemo(() => {
            let displayWidth;
            let divisor;
            let displayHeight;

            let getButton = (icon, cls) => {
                return <div className={`reddit_post_thumbnail customThumbnail ${cls ? cls : ''}`}>
                    {icon}
                </div>
            };

            let thumbnailObj = null;

            if (post.media_embed && post.media_embed.oembed && post.media_embed.oembed.thumbnail_url) {
                thumbnailObj = {
                    url: post.media_embed.oembed.thumbnail_url,
                    width: post.media_embed.oembed.thumbnail_width,
                    height: post.media_embed.oembed.thumbnail_height
                }
            } else if (post.media_embed && post.media_embed.t_url) {
                thumbnailObj = {
                    url: post.media_embed.t_url,
                    width: post.media_embed.t_width,
                    height: post.media_embed.t_height
                }
            }

            if (thumbnailObj) {
                displayWidth = screenWidth <= 420 ? 75 :
                        breakpoint.val <= SM.val ? 100 :
                            150;

                divisor = thumbnailObj.width / displayWidth;
                displayHeight = thumbnailObj.height / divisor;
                return <Image className={'reddit_thumbnail'}
                              width={displayWidth}
                              height={displayHeight}
                              style={{
                                  float: "left",
                                  backgroundImage: `url(${thumbnailObj.url})`,
                                  backgroundSize: `${displayWidth}px ${displayHeight}px`,
                              }}/>
            }
            if (isSelfPost(post)) {
                return getButton(<BsFileText/>)
            }
            if (isImage) {
                return getButton(<BsFillImageFill/>)
            }
            if (isVideo) {
                return getButton(<FaPlay/>, "playIcon")
            }
            return getButton(<BsLink/>)

        }, [post, isImage, isVideo, breakpoint, screenWidth]
    )

    /**
     * Add an embedded piece of media to the queue
     */
    let addToQueueButtonClicked = useCallback(() => {
        if (embeddableNoMirrors) {
            addEmbeddableToQueue(null, post)
        } else {
            setButtonClicked({time: Date.now(), prevState: isExpanded})
        }
    }, [post, queueContext, buttonClicked, isExpanded])

    /**
     * Return the html to be displayed when an embedded piece of media is expanded
     */
    let expandedMedia = useMemo(() => {
        if (isExpanded) {
            let objs = []
            if (post.selftext) {
                objs.push(<RedditMarkdown key={'markdown'} body={post.selftext} post={post}/>)
            }
            if (isImage) {
                if (isRedditGallery) {
                    let d =
                        <div key={post.id + "_gallery"}>
                            <EmbeddedRedditPost useIframe={false} post={post}/>
                        </div>
                    objs.push(d)
                } else {
                    let url = post.url
                    if (post.url.includes("reddit.com/media?")) {
                        let urlObj = new URL(post.url)
                        url = urlObj?.searchParams?.get("url")
                    }
                    // objs.push(<Anchor href={post.url} key={'imageanchor'}>
                    //     <Image className={"expanded_image responsive_image"}
                    //            fluid
                    //            src={url}/>
                    // </Anchor>)
                    // TODO fix resize images: https://www.npmjs.com/package/react-drag-resize
                    objs.push(<ResizableImage key={post.id} post={post}/>)
                }
            } else if (isVideo) {
                // TODO ??
            }
            return objs
            // return null
        }
        return null;
    }, [isExpanded, post, isImage, isVideo, addToQueueButtonClicked])

    let expandoIcon = useMemo(() => {
        if (embeddableNoMirrors) {
            return getSiteIconForLink(post.url)
        } else if (isImage) {
            return <BsFillImageFill/>
        } else if (isVideo) {
            return <FaPlay/>
        } else if (post.selftext) {
            return <BsFileText/>
        }
        return null
    }, [post, isVideo, isImage, embeddableNoMirrors])


    // noinspection JSUnresolvedVariable
    let permalink = useMemo(() => {
        return getRedditPermalink(post.permalink);
    }, [post.permalink])


    return (
        <Container className={"reddit_post"}>
            <Row>
                <Col xs={3} sm={2} md={3} className={"thumbnailColumn"}>
                    {thumbnail}
                </Col>
                <Col xs={9} sm={10} md={9}>
                    <div className={`reddit_post_data`}>
                        {/* Score */}
                        <RedditLink permalink={permalink} showArrow={true} post={post}/>

                        {/*Reddit post title*/}
                        <FlairAndTitle post={post}/>

                        <Row>
                            {showExpandoButton &&
                                // Enqueue / expando button
                                <Col xs={2} sm={1}>
                                    <Button aria-label={"Enqueue button"}
                                            variant={"primary"}
                                            className={"expand_or_enqueue_button"}
                                            onClick={addToQueueButtonClicked}>
                                        {expandoIcon}
                                    </Button>
                                </Col>
                            }

                            <Col>
                                {/* Date */}
                                <small>
                                    <>{new Date(post.created_utc*1000).toDateString()}</>
                                    {/*by&nbsp;*/}
                                    {/*<Anchor href={`https://www.reddit.com/u/${post.author}`}>/u/{post.author}</Anchor>*/}
                                </small>
                                <br/>
                                {/*Awards*/}
                                {post.num_awards > 0 &&

                                    <small>
                                        <span><b>{post.num_awards}</b> {post.num_awards > 1 ? 'awards' : 'award'}</span>
                                    </small>
                                }
                            </Col>
                        </Row>
                        {post.mirrors && post.mirrors.length > 0 &&
                            <MusicMirrors post={post}
                                          icon={
                                              <div style={{paddingTop: "1rem"}}>
                                                  {queueIcon}
                                              </div>}
                                          usePopup={false}/>
                        }
                    </div>
                </Col>
            </Row>
            <span className={"expanded-media"}>
                {expandedMedia}
            </span>
        </Container>
    )
}
