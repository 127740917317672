import React, {useContext, useEffect} from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Link} from "@reach/router";
import {VerifiedAccountsContext} from "../../../util/context/VerifiedAccountsContext";
import {sss} from "../../../util/Util";
import Button from "react-bootstrap/Button";
import MyProgressBar from "./MyProgressBar";
import {getIconAlt, getIconForMedia} from "../util/SiteIcons";
import {SpotifyNowPlaying} from "./SpotifyNowPlaying";
import {MediaQueueWrapperContext} from "../state/MediaQueueWrapper";
import AlternatingIcons from "../util/AlternatingIcons";
import {SpotifyContext} from "./SpotifyConnect";

export function R4RSpotifyPlayer(props) {

    let queueContext = useContext(MediaQueueWrapperContext);
    let {spotifyConnect, spotifyState} = useContext(SpotifyContext);

    let {spotifyAuth} = useContext(VerifiedAccountsContext);
    let {spotifyUsername} = spotifyAuth;

    /**
     * Checks if the pause/play display state matches between localState and the media queue context
     */
    useEffect(() => {
        if (spotifyConnect.isInitializing()) {
            props.disablePlayCallback(true)
        }
        if (spotifyConnect.isState([sss.READY_TO_PLAY_STATUS, sss.FAILED_PLAY_STATUS])) {
            props.disablePlayCallback(false)
        }
    }, [queueContext.data.isPlaying, props, spotifyConnect, spotifyState.main])

    let icon = spotifyState?.status?.icon;
    // noinspection ConstantConditionalExpressionJS
    return (
        <Container fluid className={"customSpotifyContainer"}>
            <Row>
                <Col className={"no_padding"}>

                    {spotifyUsername &&
                        <span className={"white_text d-inline-block h-100"}>
                            &nbsp;
                            {spotifyUsername}
                            &nbsp;
                            &nbsp;
                        </span>
                    }

                    {spotifyState.status.displayStr &&
                        <span className={"white_text d-inline-block h-100"}>
                            &nbsp;
                            <span>
                                {Array.isArray(icon) ? (
                                    <AlternatingIcons icons={icon}/>
                                ) : icon}
                                <small>
                                    &nbsp;{spotifyState.status.displayStr}
                                </small>
                            </span>
                            &nbsp;
                            &nbsp;
                            &nbsp;
                        </span>
                    }


                    {/*{!loggedIn ? (*/}
                    {false ? (
                        <>
                            &nbsp;
                            <Link to={"/welcome/login"}>
                                <Button>
                                    Sign in to R4R
                                </Button>
                            </Link>
                        </>
                    ) : spotifyConnect.isState([sss.STAGE_ZERO_STATUS, sss.FAILED_CONNECT_STATUS]) ? (
                        <>
                            {/* todo add this back sss.FAILED_PLAY_STATUS?
                            There could be multiple reasons it failed to play, including the track was removed */}
                            &nbsp;
                            <Button onClick={() => {
                                spotifyConnect.api.goToSpotifyAuthUrl()
                                return "Ok"
                            }}>Sign in to Spotify
                                <img width={"35px"} src={getIconForMedia('spotify')} alt={getIconAlt('spotify')}/>
                            </Button>
                        </>
                    ) : null}

                </Col>
            </Row>

            <SpotifyNowPlaying/>

            <MyProgressBar/>

        </Container>
    )
}
