import Form from "react-bootstrap/Form";
import {Link, useNavigate} from "@reach/router";
import Button from "react-bootstrap/Button";
import React, {useContext, useRef, useState} from "react";
import {ERROR_TOAST, WARNING_TOAST} from "../../util/Util";
import {HttpContext} from "../../util/hooks/useHttp";
import {MyToastContext} from "../../util/context/MyToastContext";
import Loading from "../Loading";
import {VerifiedAccountsContext} from "../../util/context/VerifiedAccountsContext";
import Anchor from "../Anchor";
import Image from "react-bootstrap/cjs/Image";
import google_button from "../../resources/btn_google_signin_dark_normal_web@2x.png";
import {useMyRecaptcha} from "../../util/hooks/UseMyRecaptcha";
import {useStateWithSessionStorage} from "../../util/hooks/useSessionStorage";

export default function LoginForm(props) {
    let nav = useNavigate()
    let httpContext = useContext(HttpContext);
    let toastContext = useContext(MyToastContext);
    let accountContext = useContext(VerifiedAccountsContext);
    let [username, setUsername] = useState("");
    let [password, setPassword] = useState("");
    let [failedLoginAttempts, setFailedLoginAttempts] = useStateWithSessionStorage("login_attempts", 0);
    let [waitingForResponse, setWaitingForResponse] = useState(false);
    // let [failedLoginCountBeforeCaptcha, setFailedLoginCountBeforeCaptcha] = useState(3);

    let {needsCaptcha, solvedCaptcha, setSolvedCaptcha, setNeedsCaptcha, captchaDomObject} = useMyRecaptcha({
        errorCallback: () => {
            toastContext.addToast("Captcha failed", ERROR_TOAST)
        },
        successCallback: () => {
            setFailedLoginAttempts(0);
        }
    })

    function handleKeyPress(event) {
        if (event.key === "Enter") {
            initiateLogin();
        }
    }

    function initiateLogin() {
        if (needsCaptcha && !solvedCaptcha) {
            toastContext.addToast("Please solve the captcha", WARNING_TOAST);
        } else {
            let postSignupRoundup = sessionStorage.getItem("roundup_signup")
            let body = {"username": username, "password": password}
            if (postSignupRoundup) {
                body["roundupSignup"] = postSignupRoundup
            }

            let opts = {
                method: "POST",
                body: JSON.stringify(body)
            }
            setWaitingForResponse(true)
            httpContext.sendRequest("/login", opts)
                .then(function (resp) {
                    setWaitingForResponse(false)
                    if (resp && resp.success) {
                        sessionStorage.removeItem("roundup_signup")
                        accountContext.setUserId(resp.success)
                        if (resp.navTo) {
                            nav(resp.navTo)
                        } else {
                            window.location.pathname = "/";
                        }
                    }
                })
                .catch(function () {
                    setWaitingForResponse(false)
                    let newFailedNumber = failedLoginAttempts + 1
                    setFailedLoginAttempts(newFailedNumber);
                    let divThree = newFailedNumber % 3
                    if (divThree === 0) {
                        setNeedsCaptcha(true)
                        setSolvedCaptcha(false)
                    }
                    toastContext.addToast("Username or password is incorrect", ERROR_TOAST);
                })
        }
    }

    return (

        <Form>
            {/*Username*/}
            <Form.Group controlId="formBasicUsername" className={"centerDiv-fifty"}>
                <Form.Label style={{float: "left"}}>Username:</Form.Label>
                <Form.Control value={username}
                              autoFocus={true}
                              onKeyPress={handleKeyPress}
                              autoComplete={"username"}
                              onChange={(e) => setUsername(e.target.value)}
                              type="username" placeholder="Enter username"/>
            </Form.Group>
            <br/>
            {/*Password*/}
            <Form.Group controlId="formBasicPassword" className={"centerDiv-fifty"}>
                <Form.Label style={{float: "left"}}>Password:</Form.Label>
                <Form.Control value={password}
                              onKeyPress={handleKeyPress}
                              autoComplete={"current-password"}
                              onChange={(e) => setPassword(e.target.value)}
                              type="password" placeholder="Password"/>
            </Form.Group>
            <div className={"centerDiv-fifty"}>

                <span style={{float: "left"}}>
                    Don't have an account?&nbsp;
                    <Link className={"underline"} to={"/welcome/signup"}>
                        Sign up
                    </Link>
                </span>
            </div>
            <br/>
            <div className={"centerDiv-fifty"}>
                <span style={{float: "left"}}>
                    <Link className={"underline"} to={"/forgotPassword"}>
                        Forgot your password?
                    </Link>
                </span>
            </div>
            <br/>
            <br/>
            <br/>

            {waitingForResponse ? (
                <Loading/>
            ) : (
                <>
                    {/*login submit*/}
                    <Button style={{marginRight: '15px'}} variant="primary"
                            onClick={() => initiateLogin()}>
                        Submit
                    </Button>
                    {" "}
                    <Button variant="secondary" onClick={() => nav("/welcome")}>
                        Cancel
                    </Button>
                    <br/>
                    <br/>
                    Or
                    <br/>

                    {/*Google button*/}
                    <Anchor target={"_self"} href={"/oauth2/authorization/google"}>
                        <Image width={"200px"} src={google_button}/>
                    </Anchor>

                </>

            )}

            {needsCaptcha && !solvedCaptcha &&
                captchaDomObject
            }


        </Form>
    )
}