import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState
} from 'react'
import Button from "react-bootstrap/Button";
import {useNavigate} from "@reach/router";
import {HttpContext} from "../../util/hooks/useHttp";
import {MyToastContext} from "../../util/context/MyToastContext";
import {ERROR_TOAST, INFO_TOAST, logger, SUCCESS_TOAST, WARNING_TOAST} from "../../util/Util";
import Card from "react-bootstrap/Card";
import {List, Popup} from "semantic-ui-react";
import RedditPost from "./RedditPost";
import RedditComment from "./RedditComment";
import CollapsableCard from "../CollapsableCard";
import {TbArrowBack} from "react-icons/tb";
import {SubredditConfigContext} from "../../util/context/SubredditConfigContext";
import {VerifiedAccountsContext} from "../../util/context/VerifiedAccountsContext";
import {Container, Row, Col} from "react-bootstrap";
import {IoMdArrowRoundUp, IoMdArrowRoundDown} from "react-icons/io";
import Loading from "../Loading";
import {ANON_USER, NULL_USER} from "../../App";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import {useGetElementAsync} from "../../util/hooks/useGetElementAsync";
import ScriptTag from 'react-script-tag';
import { PiEyesFill } from "react-icons/pi";
import { IoEyeSharp } from "react-icons/io5";
import {round} from "react-spotify-web-playback/lib/utils";
import {FaCompressArrowsAlt, FaExpandArrowsAlt, FaTrash} from "react-icons/fa";
import * as PropTypes from "prop-types";
import RoundupMatcher from "./RoundupMatcher";
import useDetectMobileScreen from "../../util/hooks/useDetectMobileScreen";

/**
 *
 id = 1
 dateStr = "08/17/21"
 username = "TheSox3"
 subreddit = "hiphopheads"
 numberOfDays = 7
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function RoundupLabel(props) {
    let roundup = props.roundup;
    let configContext = useContext(SubredditConfigContext);
    let httpContext = useContext(HttpContext);
    let toastContext = useContext(MyToastContext);
    let nav = useNavigate();
    let {screenWidth} = useDetectMobileScreen();

    let [isLoading, setIsLoading] = useState(false)

    let [isPopupOpen, setIsPopupOpen] = useState(false)

    const viewRoundup = function () {
        configContext.setViewedRoundup(roundup.id, roundup.subreddit)
        nav(`/roundups/${roundup.id}`)
    };

    function markViewed() {
        let rid = roundup.id
        let sub = roundup.subreddit
        configContext.setHiddenRoundup(rid, sub)
        // setIsLoading(true)
        httpContext.sendRequest("/api/hideRoundup/" + rid, "PUT")
            .then(r => {
                configContext.setHiddenRoundup(rid, sub)
                // setIsLoading(false)
            })
            .catch((e) => {
                // toastContext.addToast("Error, something went wrong", ERROR_TOAST);
                // setIsLoading(false)
            })
    }

    let hideButton =
        <Button variant={"outline-danger"} onClick={markViewed}>
            {/*<IoEyeSharp />*/}
            <FaTrash/>
            {/*<PiEyesFill/>*/}
        </Button>

    return isLoading ? (
        <Loading/>
    ) : !roundup && !props.text ? (
        <Row className={"emptyNavRow"}>
            <Col>&nbsp;</Col>
        </Row>
    ) : (
        <>
        <Row className={"roundupListItem"}>
            <Col xs={8}>
                <Button variant={roundup.views > 0 ? "outline-primary" : "primary"} className={"roundupViewButton"} onClick={viewRoundup}>
                    <span>
                        <b>{roundup.date}.</b>&nbsp;
                        {roundup && roundup.numberOfDays === 1 ? 'Daily' : 'Weekly'}
                    </span>
                </Button>
            </Col>
            <Col xs={4} className={"mark_viewed_col"}>
                {hideButton}
            </Col>
        </Row>

        </>
    )

}


function isElementVisible(element, container) {
    const elRect = element.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();

    let result = false;

    if(elRect.x >= containerRect.x && elRect.y >= containerRect.y
        && elRect.x + elRect.width <= containerRect.x + containerRect.width
        && elRect.y + elRect.height <= containerRect.y + containerRect.height)
    {
        result = true
    }

    return result;
}



export function ViewRoundup(props) {
    let {userId, loggedIn, displayName} = useContext(VerifiedAccountsContext)
    let toastContext = useContext(MyToastContext);
    let nav = useNavigate();
    let roundupId = Number(props.roundupId)
    let httpContext = useContext(HttpContext)
    let {roundupData, setRoundupMeta, setRoundupData, setViewedRoundup, loadSingleConfig} = useContext(SubredditConfigContext);

    let [isLoading, setIsLoading] = useState(false)
    let [isLoadingMeta, setIsLoadingMeta] = useState(false)
    let [loadedId, setLoadedId] = useState(null)
    let [didScroll, setDidScroll] = useState(null)


    const thisNav = useGetElementAsync(".thisRoundupNav")
    useEffect(() => {
        if (thisNav && thisNav.length === 2 && roundupId && didScroll !== roundupId && loadedId === roundupId) {
            let [left, right] = document.querySelectorAll(".thisRoundupNav")
            // logger("Scrolling for roundupid", roundupId)
            let leftParent = document.querySelector(".left_side.col")
            let rightParent = document.querySelector(".right_side.col")
            if (!isElementVisible(left, leftParent)) {
                left.scrollIntoView({block: 'center'})
            }
            if (!isElementVisible(right, rightParent)) {
                right.scrollIntoView({block: 'center'})
            }
            setDidScroll(roundupId)
        }
    }, [didScroll, roundupId, thisNav, loadedId])

    let thisMeta = useMemo(() => {
        let meta = roundupData.roundups[roundupId]?.meta || {}
        if (!meta?.subreddit && Object.entries(roundupData.metaBySub).length > 0) {
            let meta = Object.entries(roundupData.metaBySub).find(([key, val], indx) => {
                if (val && val.length) {
                    return val.find((m) => m.id + "" === roundupId + "") != null
                }
                return false;
            })
            if (meta?.length) {
                return meta[1].find((m) => m.id + "" === roundupId + "")
            }
        }
        return meta
    }, [roundupData.metaBySub, roundupData.roundups, roundupId])

    let thisRoundup = useMemo(() => {
        return roundupData.roundups[roundupId] || {}
    }, [roundupData.roundups, roundupId])

    useEffect(() => {
        if (roundupId && roundupId !== loadedId) {
            setLoadedId(roundupId);
            if (!thisRoundup.posts) {
                setIsLoading(true)
                httpContext.sendRequest(`/api/roundups/${roundupId}`, "GET")
                    .then((resp) => {
                        setIsLoading(false)
                        logger("/roundups", resp.posts.map(r => r.post))
                        logger("/roundups matchers", resp.matchers)
                        setRoundupData(resp, roundupId)
                    })
                    .catch((resp) => {
                        setIsLoading(false)
                        toastContext.addToast("Error loading roundup id " + roundupId, ERROR_TOAST)
                    })
            }
        }
    }, [httpContext, loadedId, roundupId, setRoundupData, thisRoundup.posts, toastContext]) //ignore toastContext, http

    useEffect(() => {
        if (roundupId && !thisMeta.subreddit && !isLoadingMeta) {
            setIsLoadingMeta(true)
            httpContext.sendRequest(`/api/roundupsMeta/${roundupId}`, "GET")
                .then((resp) => {
                    setRoundupMeta(resp, true)
                    setIsLoadingMeta(false)
                })
                .catch((resp) => {
                    // setIsLoadingMeta(false)
                })
        }
    }, [httpContext, isLoadingMeta, roundupData.loadedMeta, roundupData.roundups, roundupId, setRoundupMeta, thisMeta]) //ignore toastContext, http

    let signupForRoundup = useCallback(() => {
        sessionStorage.setItem("roundup_signup", roundupId)
        if (userId && userId !== ANON_USER && userId !== NULL_USER) {
            toastContext.addToast("Signing you up...", INFO_TOAST)
            httpContext.sendRequest("/copyConfig/" + roundupId, "PUT")
                .then(() => {
                    toastContext.addToast("Success", SUCCESS_TOAST)
                    loadSingleConfig(thisMeta.subreddit)
                })
                .catch((resp) => {
                    if (resp && resp["error"] && resp["error"] === "exists") {
                        toastContext.addToast("You already have a config for this subreddit. " +
                            "If you don't see it on the homepage; try refreshing the browser", ERROR_TOAST)
                    } else {
                        toastContext.addToast("Something went wrong", ERROR_TOAST)
                    }
                })
        } else {
            nav("/welcome/signup")
        }
    }, [httpContext, nav, roundupId, toastContext, userId, thisMeta.subreddit])

    let isAuthUser = useMemo(() => {
        logger("display:", displayName)
        return (userId && loggedIn && thisMeta?.authUserId + "" === userId + "")
    }, [loggedIn, thisMeta?.authUserId, userId, displayName])

    let subRoundups = roundupData.metaBySub[thisMeta.subreddit]
    let allSubs = Object.keys(roundupData.metaBySub).sort()
    let thisRndpIndex = subRoundups && subRoundups.length ? subRoundups.findIndex((r) => r.id + "" === roundupId + "") : null
    let thisSubIndex = allSubs && allSubs.length ? allSubs.indexOf(thisMeta.subreddit) : null

    const viewRoundup = function (roundup) {
        setViewedRoundup(roundup.id, roundup.subreddit)
        nav(`/roundups/${roundup.id}`)
    };
    // noinspection JSValidateTypes
    return (
        <>
            <ScriptTag type="text/javascript"
                       src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4971267553854299"
            />
            <Card className={"top_level_card roundup_card"}>
                <Card.Header>
                    <Row className={"roundup_header"}>
                        <Col xs={3}>
                            <Button variant={"secondary"}
                                    onClick={() => nav("/")}>
                                <TbArrowBack/> Home
                            </Button>
                        </Col>
                        {!isAuthUser && thisMeta.subreddit && thisMeta.date && (
                            <>
                                <Col xs={9} sm={3} style={{alignContent: "center"}}>
                                    <b style={{textTransform: "uppercase"}}><span
                                        className={"no_wrap"}>{thisMeta.subreddit}</span> - <span
                                        className={"no_wrap"}>{thisMeta.date}</span></b>
                                </Col>
                                <Col xs={6} sm={4}>
                                    <Button style={{float: "right"}} onClick={signupForRoundup}>
                                        Sign up for this roundup
                                    </Button>
                                </Col>
                            </>
                        )}

                    </Row>

                    {isAuthUser && roundupData.loadedMeta && thisMeta?.subreddit && (
                        <Container fluid className={"nav_wrapper"}>
                            <Row>
                                <Col className={"left_side"}>
                                    <List variant={"flush"} className="nav_list">
                                        {allSubs.map((sub, index) => {
                                            let nextRndup = roundupData.metaBySub[sub][0]
                                            let className = "roundupNavRow" + (index === thisSubIndex ? " thisRoundupNav" : "")
                                            return (
                                                <List.Item className={className}
                                                               onClick={() => {
                                                                   viewRoundup(nextRndup)
                                                               }}
                                                               key={sub}>
                                                    {sub}
                                                </List.Item>
                                            )
                                        })}
                                    </List>
                                </Col>
                                <Col className={"right_side"}>
                                    <List variant={"flush"} className="nav_list">
                                        {subRoundups.map((meta, index) => {
                                            let className = "roundupNavRow" + (index === thisRndpIndex ? " thisRoundupNav" : "")
                                            return (
                                                <List.Item className={className}
                                                               onClick={() => {
                                                                   viewRoundup(meta)
                                                               }}
                                                               key={meta.id}>
                                                    {meta.date}
                                                </List.Item>
                                            )
                                        })}
                                    </List>
                                </Col>
                            </Row>
                        </Container>
                    )}
                </Card.Header>
                <Card.Body>
                    {isLoading && <Loading/>}
                    {/*<ListGroup>*/}
                    {thisRoundup?.matchers && thisRoundup?.matchers?.map((matcher) => {
                        return (
                            <RoundupMatcher thisRoundup={thisRoundup}
                                            matcher={matcher}
                                            key={matcher.id}/>
                        )
                    })}

                </Card.Body>
            </Card>
        </>

    )
}