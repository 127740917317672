import {
    AND_FILTER,
    createComboFilter,
    createFilterObject, createNewSectionId,
    createSectionObject, createSubredditConfigObject, logger,
    OR_FILTER, SORT_BY_COMMENTS,
    SORT_BY_UPVOTES
} from "./Util";

function createCatArray(cat, isNegated) {
    let catArr = []
    if (cat.title) {
        catArr.push(createFilterObject(true, false, false, false,
            cat.title, isNegated, cat.id));
    }
    if (cat.flair) {
        catArr.push(createFilterObject(false, true, false, false,
            cat.flair, isNegated, cat.id));
    }
    if (cat.link) {
        catArr.push(createFilterObject(false, false, true, false,
            cat.link, isNegated, cat.id));
    }
    if (cat.selftext) {
        catArr.push(createFilterObject(false, false, false, true,
            cat.selftext, isNegated, cat.id));
    }
    return catArr;
}

function parseParenthesesString(str) {
    let i = 0;

    function recurseParen() {
        let arr = [];
        let startIndex = i;

        function addWord() {
            if (i - 1 > startIndex) {
                if (["(", ")", " "].includes(str[i - 1])) {
                    arr.push(str.slice(startIndex, i - 1));
                } else {
                    arr.push(str.slice(startIndex, i));
                }
            }
        }

        while (i < str.length) {
            switch (str[i++]) {
                case " ":
                    addWord();
                    startIndex = i;
                    continue;
                case "(":
                    arr.push(recurseParen());
                    startIndex = i;
                    continue;
                case ")":
                    addWord();
                    return arr;
                default:
                // ignore
            }
        }
        addWord();
        return arr;
    }

    return recurseParen();
}

const createFilterForParenthesesGroup = function (parenToken, allCategories, isNegated) {
    let lastFilter = null;
    // tokenize the category string
    let categorySplit = parenToken.split(" ");

    let isAnded = false;
    let isOred = false;
    for (const nextIndex in categorySplit) {
        // noinspection JSUnfilteredForInLoop
        let nextToken = categorySplit[nextIndex].trim();
        if (!nextToken) {
            continue;
        }
        // check for special tokens: or, and, not
        if (nextToken === "not") {
            isNegated = true;
            continue;
        }
        if (nextToken === "and") {
            isAnded = true;
            continue;
        }
        if (nextToken === "or") {
            isOred = true;
            continue;
        }

        // find the category with this name
        let cat = allCategories.find((catObj) => {
            return catObj.name === nextToken;
        });
        if (!cat) {
            return null;
        }

        // create array of filters based on the category and OR them together
        let catArr = createCatArray(cat, isNegated);
        let thisFilter = catArr.length > 1
            ? createComboFilter(catArr, OR_FILTER)
            : catArr[0];

        // noinspection DuplicatedCode
        if (isAnded && lastFilter) {
            thisFilter = createComboFilter([lastFilter, thisFilter], AND_FILTER);
            isAnded = false;
        } else if (isOred && lastFilter) {
            thisFilter = createComboFilter([lastFilter, thisFilter], OR_FILTER);
            isOred = false;
        }

        lastFilter = thisFilter;
        isNegated = false;
    }
    return lastFilter;
};

function recursivelyParseCategoryArray(catArray, allCategories, isNegated) {
    if (!Array.isArray(catArray)) {
        return createFilterForParenthesesGroup(catArray, allCategories, isNegated);
    }
    let childArrayExists = catArray.find((ca) => {
        return Array.isArray(ca);
    });
    // this function needs to handle the following cases:
    // 1. catArray is just strings
    if (!childArrayExists) {
        let catString = catArray.length === 1 ? catArray[0] : catArray.join(" ");
        return createFilterForParenthesesGroup(catString, allCategories, null);
    }

    // 2. catArray contains children arrays
    let children = [];
    let comboType = null;
    // set isNegated to false because isNegated should only apply to singular filters, not a group of filters
    isNegated = false;
    for (const index in catArray) {
        // noinspection JSUnfilteredForInLoop
        let nextElement = catArray[index];
        if (nextElement === "or" || nextElement === "and") {
            if (comboType && comboType !== nextElement.toUpperCase()) {
                logger("uhoh");
            }
            comboType = nextElement.toUpperCase()
        } else if (nextElement === "not") {
            isNegated = true;
        } else {
            let nextCombo = recursivelyParseCategoryArray(nextElement, allCategories, isNegated);
            children.push(nextCombo);
        }
    }
    if (children.length > 1) {
        return createComboFilter(children, comboType);
    } else {
        return children[0];
    }
}

// noinspection DuplicatedCode
const getFilterListFromCategory = function (categoryStr, allCategories) {
    if (!categoryStr) {
        return [];
    }
    let catArray = parseParenthesesString(categoryStr);
    let filter = recursivelyParseCategoryArray(catArray, allCategories);
    return Array.isArray(filter) ? filter : [filter];
}

function isString(val) {
    return typeof (val) === "string"
}

export const convertBackendToFrontendJson = function (theJson) {
    if (!Array.isArray(theJson)) {
        theJson = [theJson]
    }
    // noinspection UnnecessaryLocalVariableJS
    let subObjs = theJson.map((sub) => {
        let subName = sub.subreddit;
        let mailTime = sub.mail_time;
        let deliveryMethod = sub.delivery_method;
        let daily = sub.mail_daily;
        let dayToMail = sub.day_to_post;

        // parse categories
        let categories = []
        if (sub.categories) {
            categories = isString(sub.categories) ? JSON.parse(sub.categories) : sub.categories;
        }
        categories = categories.map((cat) => {
            if (cat.title) {
                cat.title = isString(cat.title) ? JSON.parse(cat.title) : cat.title
            }
            if (cat.selftext) {
                cat.selftext = isString(cat.selftext) ? JSON.parse(cat.selftext) : cat.selftext
            }
            if (cat.link) {
                cat.link = isString(cat.link) ? JSON.parse(cat.link) : cat.link
            }
            if (cat.flair) {
                cat.flair = isString(cat.flair) ? JSON.parse(cat.flair) : cat.flair
            }
            return cat;
        });

        let matchers = []
        if (sub.matchers) {
            matchers = isString(sub.matchers) ? JSON.parse(sub.matchers) : sub.matchers;
        }
        // parse config sections
        let configSections = matchers.map((m, index) => {
            let displayName = m.display_name || m.nickname || m.category;
            let postLimit = m.limit;
            let upvoteThreshold = m.upvotes;
            if (!upvoteThreshold) {
                upvoteThreshold = 0;
            }

            let isDefaultName = false;
            if (m.isDefaultName !== undefined) {
                isDefaultName = m.isDefaultName;
            }

            let searchForPosts = !m.type || m.type === "post" || m.type === "posts";

            let sortBy = m.sort;
            if (!sortBy) {
                sortBy = SORT_BY_UPVOTES;
            }
            if (sortBy === "comment") {
                sortBy = SORT_BY_COMMENTS;
            }

            // parse filters/matchers
            let categoryStr = m.category;
            let filterList = getFilterListFromCategory(categoryStr, categories)
            if (!filterList) {
                return null;
            }
            let awardThreshold = 0;
            if (!m.matcher_id) {
                m.matcher_id = createNewSectionId()
            }
            let sectionObj = createSectionObject(searchForPosts, sortBy, filterList, postLimit, upvoteThreshold, awardThreshold,
                displayName, isDefaultName, m.matcher_id);
            sectionObj.priority = m.priority ? Number(m.priority) : index + 1;
            return sectionObj;
        });
        configSections.sort((a, b) => a.priority > b.priority ? 1 : -1)
        // noinspection UnnecessaryLocalVariableJS
        let configObj = createSubredditConfigObject(subName, configSections, deliveryMethod, daily, mailTime, dayToMail)

        configObj.id = sub.id;
        return configObj
    })
    return subObjs || [];
};
