import {createContext, useCallback, useMemo} from "react";
import {site_const} from "../../components/media_queue/util/site_const";
import {isCanonLink} from "../../components/roundup/RedditPost";
import {cloneDeep, logger, SORT_BY_AWARDS, SORT_BY_UPVOTES} from "../Util";
import {getSiteFromUrl} from "../../components/media_queue/util/SiteIcons";

export const SubredditConfigContext = createContext(null);

export const defaultRoundup = {
    loadedMeta: false,
    metaBySub: {},
    roundups: {}
}
export const SET_HIDDEN = "SET_HIDDEN";
export const SET_VIEWED = "SET_VIEWED";
export const SET_METADATA = "SET_METADATA";
export const SET_SINGLE_METADATA = "SET_SINGLE_METADATA";
export const SET_ROUNDUP_POSTS = "SET_ROUNDUP_POSTS";
export function roundupReducer(existing, action) {
    let newData = cloneDeep(existing)
    let roundupId, subreddit, thisRoundup, subRoundups;
    switch (action.type) {
        case SET_VIEWED:
            roundupId = action.payload.rid
            subreddit = action.payload.sub
            // remove roundup from roundup data
            thisRoundup = newData.roundups[roundupId]
            if (thisRoundup) {
                thisRoundup.views = 1
            }

            subRoundups = newData.metaBySub[subreddit]
            if (subRoundups && subRoundups.length > 0) {
                let thisOne = newData.metaBySub[subreddit].filter((next) => {
                    return next.id === roundupId
                })
                if (thisOne && thisOne.length > 0) {
                    thisOne[0].views = 1
                }
            }
            break;
        case SET_HIDDEN:
            roundupId = action.payload.rid
            subreddit = action.payload.sub
            // remove roundup from roundup data
            thisRoundup = newData.roundups[roundupId]
            if (thisRoundup) {
                delete newData.roundups[roundupId]
            }
            // Remove roundup from metadata
            subRoundups = newData.metaBySub[subreddit]
            if (subRoundups && subRoundups.length > 0) {
                newData.metaBySub[subreddit] = newData.metaBySub[subreddit].filter((next) => {
                    return next.id !== roundupId
                })
                if (newData.metaBySub[subreddit].length === 0) {
                    delete newData.metaBySub[subreddit]
                }
            }
            break;
        case SET_SINGLE_METADATA:
            let sub = action.payload["subreddit"]
            if (newData?.metaBySub[sub]) {
                var existingIndex = newData.metaBySub[sub].findIndex(m => m.id === action.payload.id)
                if (existingIndex !== -1) {
                    newData.metaBySub[sub].splice(existingIndex, 1, action.payload)
                } else {
                    newData.metaBySub[sub].push(action.payload)
                }
            } else {
                newData.metaBySub[sub] = [action.payload]
            }
            break;
        case SET_METADATA:
            newData.metaBySub = action.payload;
            newData.loadedMeta = true
            break;
        case SET_ROUNDUP_POSTS:
            let transformed = sortAndGroupMatchers(action.payload)
            newData.roundups[action.rid] = transformed;
            break;
        default:
            break;
    }
    return newData;
}

export const isVideoPost = (post) => {
    // todo fix this. Test with /r/hockey or nfl
    // let validUrls = ["v.redd.it"]
    // let validUrls = []
    // let findValid = validUrls.find((url) => post.url.includes(url))
    // return findValid != null;
    return false;
}
export const isImagePost = (post) => {
    let validUrls = ["i.redd.it", "imgur.com", "reddit.com/gallery"]
    try {
        let findValid = validUrls.find((url) => post?.url?.includes(url))
        return findValid != null;
    }catch (e) {
        return false
    }
}
export const showExpandoButtonPost = (post) => {
    return post.selftext || isImagePost(post) || embeddableNoMirrorsPost(post)
}

export const isRedditGalleryPost = (post) => {
    return post?.url?.includes("reddit.com/gallery")
}
export const isApprovedSitePost = (post) => {
    let match = ["music.apple.com", "soundcloud.app.goo.gl", "soundcloud.com",
        "open.spotify.com", "spotify.lnk", "youtube.com", "youtu.be", "v.redd.it",
        "bandcamp.com", "twitch.tv"]
        .find((approved) => post.url.includes(approved))
    return match != null;
}

export const embeddableNoMirrorsPost = (post) => {
    return isEmbeddable(post) && !post.mirrors?.length
}

// Determine if the link of this post can be embedded in the media queue
export const isEmbeddable = (post) => {
    let badTypes = ["twitter.com", "quora.com"]
    let foundMedia = post && (post.media_embed?.oembed || post.media_embed?.reddit_video)
    if (foundMedia) {
        let type = post.media_embed?.type
        foundMedia = type && !badTypes.includes(type)
    }
    let isApproved = post && post.url && isApprovedSitePost(post);
    return foundMedia || isApproved;
}

const badRedirectMirrors = ["https://www.youtube.com/watch", "www.reddit.com/media"]
let initMirrors = (roundupPost) => {
    let p = roundupPost.post
    if (!p || !p.url) {
        return null;
    }
    let originalLink = {otherLink: p.url, otherSite: getSiteFromUrl(p.url)}
    if (!roundupPost.mirrors || roundupPost.mirrors?.length === 0) {
        if (isEmbeddable(p)) {
            return [originalLink]
        }
        return []
    } else {
        let returnList = Array.from(roundupPost.mirrors || [])
        let foundGoodRedirect = returnList.find((mirror) => {
              return mirror.otherSite === "redirect" && !badRedirectMirrors.includes(mirror.otherId)
        })
        if (foundGoodRedirect) {
            // do nothing. keep the redirect as a mirror
        } else if (isEmbeddable(p)) {
            // remove any mirrors for the original site
            returnList = returnList.filter((mir) => {
                return mir.otherSite !== originalLink.otherSite
            })
            // add original link
            returnList.unshift(originalLink)
        }
        returnList = returnList.sort((a, b) => {
            return sortMirrors(a, b, roundupPost.post)
        })
        return returnList
    }
}

let sortAndGroupMatchers = function (roundupPosts) {
    let matcherMap = {}
    let matcherList = roundupPosts.matchers
    // associate each post with its matcher
    roundupPosts?.posts?.forEach((rp) => {
        let redditObj = rp.post ? rp.post : rp.comment

        redditObj.title = decodeHtmlEntities(redditObj.title)
        redditObj.body = decodeHtmlEntities(redditObj.body)

        redditObj.mirrors = initMirrors(rp) || [];

        if (redditObj && redditObj.media) {
            try {
                if (typeof redditObj.media !== "object"  ) {
                    redditObj.media = JSON.parse(redditObj.media);
                }
            }catch (e) {
                logger(e)
            }
        }
        let matcherId = rp.matcherId || rp.matcher.id
        if (matcherMap[matcherId]) {
            matcherMap[matcherId].push(redditObj);
        } else {
            matcherMap[matcherId] = [redditObj];
        }
    })

    // sort the matchers by priority
    matcherList.sort((a, b) => a.priority > b.priority ? 1 : -1)
    // sort the posts by matcher-sort
    matcherList.forEach((matcher) => {
        let allPosts = matcherMap[matcher.id]
        let sortField = null;
        if (matcher.sort === SORT_BY_AWARDS) {
            // sortField = "awardsCount"
            sortField = "num_awards"
        } else if (matcher.sort === SORT_BY_UPVOTES) {
            sortField = "score"
        } else if (matcher.sort === SORT_BY_UPVOTES) {
            sortField = "numComments"
        }
        allPosts.sort((a, b) => Number(a[sortField]) > Number(b[sortField]) ? -1 : 1)
    })
    return {"matchers": matcherList, "posts": matcherMap}
}
/**
 * Decode html entities
 * ex:
 *      &amp; ->  &
 */
function decodeHtmlEntities(str) {
    if (!str) {
        return str;
    }
    let txt = document.createElement("textarea");
    txt.innerHTML = str;
    return txt.value;
}
let isSpotify = (a) => a.otherSite === site_const.spotify
let isAppleMusic = (a) => a.otherSite === site_const.applemusic
let isYoutube = (a) => a.otherSite === site_const.youtube
let isSoundcloud = (a) => a.otherSite === site_const.soundcloud

let sortMirrors = function(a, b, post) {
    // let post = this;
    let methods = [isCanonLink, isSpotify, isAppleMusic, isYoutube, isSoundcloud]
    for (let i = 0; i < methods.length; i++) {
        let method = methods[i]
        let aResp = method(a, post)
        if (aResp) {
            return -1;
        }
        let bResp = method(b, post)
        if (bResp) {
            return 1;
        }
    }
}
