import {useCallback, useEffect, useState} from "react";

export const XXL = {val: 1400, label: "XXL"};
export const XL = {val: 1200, label: "XL"};
export const LG = {val: 992, label: "LG"};
export const MD = {val: 768, label: "MD"};
export const SM = {val: 576, label: "SM"};
export const XS = {val: 0, label: "XS"};

export default function useDetectMobileScreen() {
    let [width, setWidth] = useState(window.innerWidth);

    let getBp = useCallback(() => {
        let w = window.innerWidth

        if (w >= 1400) {
            return XXL;
        } else if (w >= 1200) {
            return XL;
        } else if (w >= 992) {
            return LG;
        } else if (w >= 768) {
            return MD;
        } else if (w >= 576) {
            return SM;
        } else {
            return XS;
        }
    }, [])

    let [breakpoint, setBreakpoint] = useState(getBp());

    const handleWindowSizeChange = useCallback(() => {
        let w = window.innerWidth
        setWidth(w);
        setBreakpoint(getBp());
    }, [getBp])

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            // cleanup: unsubscribe from this event
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, [handleWindowSizeChange]);

    return {
        isMobile: breakpoint.val <= MD.val,
        isDesktop: breakpoint.val >= MD.val,
        screenWidth: width,
        breakpoint: breakpoint
    };
}

