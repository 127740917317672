import ScriptTag from "react-script-tag";
import React, {useMemo} from "react";
import {MyScriptTag} from "../MyScriptTag";

export default function EmbeddedRedditPost (props) {

    return props.useIframe ? (
                <iframe src={"https://embed.reddit.com" + props.post.permalink +
                    "?embed=true&mute=false&ref_source=embed&ref=share&utm_medium=widgets&utm_source=embedv2&" +
                    "utm_term=23&utm_name=post_embed&embed_host_url=" + encodeURI(window.location.href)} width={"640px"}
                        // sandbox="allow-scripts allow-same-origin allow-popups"
                        allow="clipboard-read; clipboard-write"
                        style={{
                            border: "none",
                            maxWidth: "100%",
                            borderRadius: "8px",
                            display: "block",
                            margin: "0px auto"
                        }}
                        height="569"/>

            ) : (
                <>
                <blockquote key={props.post.id} className="reddit-embed-bq">
                    <a href={"https://reddit.com" + props.post.permalink}></a>
                </blockquote>
                <ScriptTag className={"reddit-embed-script"}
                             type={"text/javascript"}
                             key={props.post.id + "_script"}
                             src="https://embed.reddit.com/widgets.js" charSet="UTF-8"></ScriptTag>

                </>
            )

}

