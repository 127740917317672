import Button from "react-bootstrap/Button";
import React, {useLayoutEffect, useRef, useState} from 'react';
import ReactMarkdown from "react-markdown";
import parse from "html-react-parser";
import remarkGfm from 'remark-gfm'
import remarkParse from 'remark-parse'

export default function RedditMarkdown(props) {
    let thisRef = useRef(null);
    let [markdownText, setMarkdownText] = useState(props.body)
    let [markdownTextSpoilerFree, setMarkdownTextSpoilerFree] = useState(props.body)
    let [markdownTextSpoilersDisplayed, setMarkdownTextSpoilersDisplayed] = useState(props.body)
    let [transformedMarkdown, setTransformedMarkdown] = useState(false);
    let [spoilersFound, setSpoilersFound] = useState(false);
    let [showSpoilers, setShowSpoilers] = useState(false);

    let [finalHtml, setFinalHtml] = useState(null);

    useLayoutEffect(() => {
        // Add a space after all '#' that begin a line
        // This is invalid markdown: '#abc' but reddit counts it as valid
        if (!transformedMarkdown) {
            let reg = new RegExp("^(#+)", "gm")
            let transformed = markdownText.replace(reg, "$1 ");

            // Replace spoiler notation
            let spoilerReg = new RegExp(">!(.*)!<", "gm")
            if (markdownText.search(spoilerReg) > 0) {
                transformed = markdownText.replace(spoilerReg, ">>SPOILER<<")
                setSpoilersFound(true)
                setMarkdownTextSpoilersDisplayed(markdownText.replace(spoilerReg, "$1"))
                setMarkdownTextSpoilerFree(transformed)
            }
            setMarkdownText(transformed)
            setTransformedMarkdown(true);
        }
    }, [markdownText, transformedMarkdown])


    useLayoutEffect(() => {
        // useLayoutEffect gets called after the first render. As opposed to useEffect which gets called BEFORE first render
        if (thisRef && thisRef.current && transformedMarkdown) {
            let html = parse(thisRef.current.innerHTML.replaceAll("<a href",
                "<a target='blank' rel='noopener noreferrer' href"))
            setFinalHtml(html);
        }
    }, [thisRef, transformedMarkdown])


    return (
        <div className={"reddit_self_text"}>
            {props.header && (<div>{props.header}<br/></div>)}

            {spoilersFound &&
                <Button variant={"secondary"} onClick={() => {
                    if (showSpoilers) {
                        setShowSpoilers(false)
                        setMarkdownText(markdownTextSpoilerFree)
                        setFinalHtml(null)
                    } else {
                        setShowSpoilers(true)
                        setMarkdownText(markdownTextSpoilersDisplayed)
                        setFinalHtml(null)
                    }
                }}>{
                    showSpoilers ? 'Hide Spoilers' : 'Show Spoilers'}
                </Button>
            }

            {finalHtml !== null ? (
                <div>{finalHtml}</div>
            ) : (
                <div ref={thisRef}>
                    <ReactMarkdown
                        components={{h1: "h3", h2: "h4", h3: "h5", h4: "h6"}}
                        // remarkPlugins={[remarkParse, remarkGfm]}
                        remarkPlugins={[remarkGfm]}
                        linkTarget={"_blank"}>

                        {markdownText}

                    </ReactMarkdown>
                </div>
            )}
        </div>

    )
}