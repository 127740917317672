
export default function WebsocketListener(props) {
    let SockJs = require("sockjs-client");
    let Stomp = require("stomp-websocket");

    function registerWebsocket(callback) {
        let socket = SockJs("/redditSub")
        let stompClient = Stomp.over(socket);

        stompClient.connect({}, (frame) => {
            let url = stompClient.ws._transport.url;
            url = url.replace("ws://localhost:8080/redditSub/",  "");
            url = url.replace("ws://roundupforreddit.com/redditSub/",  "");
            url = url.replace("wss://roundupforreddit.com/redditSub/",  "");
            url = url.replace("/websocket", "");
            url = url.replace(/^[0-9]+\//, "");
            let sessionId = url;

            stompClient.subscribe("/queue/redditSubscriptions-user" + sessionId, callback);
        })
    }

    return {registerWebsocket: registerWebsocket};
}