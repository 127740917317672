import youtubeIcon from "../images/youtube_icon.png";

import spotifyIconGreen from "../images/spotify_icon_green.png";

import soundcloudIcon from "../images/sc-icon.png";

import applemusicIconSmall from "../images/am-icon.png";

import twitchIcon from "../images/TwitchGlitchPurple.svg";
import pandoraIcon from "../images/pandora_small.png";
import deezerIcon from "../images/deezer-logo.png";
import bandcampIcon from "../images/bandcamp-icon.png";
import tidalIcon from "../images/tidal-icon.png";
import amzMusicIcon from "../images/amazon-music-icon.png";
import discogsIcon from "../images/discogs logo.png";
import iheartIcon from "../images/iheart-radio-icon.png";
import instagramIcon from "../images/instagram_icon.png";
import musicbrainzIcon from "../images/MusicBrainz_logo.png";
import tuneinIcon from "../images/tunein.png";
import facebookIcon from "../images/facebook_icon.png";
import twitterIcon from "../images/twitter_icon.png";
import youtubeMusicIcon from "../images/youtube-music.png";
import geniusIcon from "../images/genius-icon.png";
import lastFmIcon from "../images/lastfm-icon.png";
import streamableIcon from "../images/streamable_icon.png";
import redditIcon from "../images/Reddit_Mark_OnWhite.png";

import {FaHome} from "react-icons/fa/index";
import React from "react";
import {site_const} from "./site_const";
import {logger} from "../../../util/Util";

export function isBigIcon(site) {
    return [site_const.discogs, site_const.music_brainz, site_const.tunein,
        site_const.iheartradio, site_const.streamable].includes(site);
}

export function getSiteFromUrl(url) {
    if (!url) {
        return site_const.unknown;
    }
    if (url.startsWith("/r/")) {
        return site_const.reddit
    }
    try {
        let u = new URL(url);
        let hostname = u.hostname.replace(/^m\./, "")
            .replace(/^on\./, "")
            .replace(/^www\./, "")
            .replace(/\.com$/, "")
            .replace(/\.tv$/, "")
            .replace(/\.link$/, "")
            .replace(/\.lnk\.to$/, "")
        hostname = hostname.replaceAll(".", "_")
        if (site_const[hostname]) {
            return site_const[hostname]
        } else if (hostname.includes("_bandcamp")) {
            return site_const.bandcamp;
        }
    }catch(err) {
        logger("Error getting site from url: ", url);
    }
    // logger(`unable to get site for url ${url}`)
    return site_const.unknown;
}

export function getSiteIcon(media, smallSize, bigSize) {
    let src = media.src
    if (media.className === "embedly-embed") {
        src = (new URL(src)).searchParams.get("url") || (new URL(src)).searchParams.get("src")
    }
    let site = getSiteFromUrl(src);
    return getImgOrIconForWebsite(site, 25, 50)
}

export function getSiteIconForLink(link) {
    let site = getSiteFromUrl(link)
    return getImgOrIconForWebsite(site, 25, 50)
}

export function getSiteIconForMirror(mirror) {
    let site = getSiteFromUrl(mirror.otherLink)
    if (site && mirror.otherSite && site !== mirror.otherSite && mirror.otherSite !== "redirect") {
        logger("fuck, mismatch", site, mirror.otherSite)
        // throw new DOMException("fuck")
    }
    return getImgOrIconForWebsite(site, 25, 50)
}

export function getImgOrIconForWebsite(site, smallSize, bigSize) {
    let width = smallSize || 35;
    if (isBigIcon(site)) {
        width = bigSize || 70;
    }
    let widthStr = width + "px";
    switch (site) {
        case "site":
            return <FaHome size={"3rem"}/>;
        default:
            return <img width={widthStr} src={getIconForMedia(site)} alt={getIconAlt(site)}/>;

    }
}

export function getIconForMedia(site) {
    switch (site) {
        case site_const.twitch:
            return twitchIcon;
        case site_const.genius:
            return geniusIcon;
        case site_const.lastfm:
            return lastFmIcon;
        case site_const.youtube:
            return youtubeIcon;
        case site_const.spotify:
            return spotifyIconGreen;
            // return spotifyIconBlack;
            // return spotifyIconWhite;
        case site_const.soundcloud:
            return soundcloudIcon
        case site_const.applemusic:
            // return applemusicIcon
            return applemusicIconSmall
        case site_const.deezer:
            return deezerIcon
        case site_const.pandora:
            return pandoraIcon
        case site_const.bandcamp:
            return bandcampIcon
        case site_const.tidal:
            return tidalIcon
        case site_const.music_amazon:
            return amzMusicIcon
        case site_const.discogs:
            return discogsIcon
        case "iheart-radio":
            return iheartIcon
        case "instagram":
            return instagramIcon
        case "musicbrainz":
            return musicbrainzIcon
        case "tunein":
            return tuneinIcon
        case "facebook":
            return facebookIcon
        case "twitter":
            return twitterIcon
        case "music.youtube":
            return youtubeMusicIcon
        case "streamable":
            return streamableIcon
        case "reddit":
            return redditIcon
        default:
            return "/favicon.ico";
    }
}

export function getIconAlt(site) {
    return site + " icon";
}