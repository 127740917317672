import React from "react"
import {getRedditPermalink} from "./RedditPost";
import RedditMarkdown from "./RedditMarkdown";
import FlairAndTitle from "./FlairAndTitle";
import Anchor from "../Anchor";

export default function RedditComment(props) {
    let comment = props.comment
    // subreddit, url, flair, title
    let parent = {
        subreddit: comment.subreddit,
        url: comment.parent_url,
        flair: comment.parent_flair,
        title: comment.parent_title
    };

    let dateSubmitted = (new Date(comment.created_utc * 1000)).toDateString()

    let header = (
        <p><b>{comment.score}</b> points
            &nbsp;-&nbsp;
            <Anchor href={getRedditPermalink(comment.permalink)}>
                /u/{comment.author}
                &nbsp;-&nbsp;
                {dateSubmitted}
            </Anchor>
        </p>
    );

    return (
        <div className={"reddit_post"}>
            <FlairAndTitle post={parent}/>

            <RedditMarkdown header={header}
                            body={comment.body}/>
        </div>
    )
}