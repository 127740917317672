import React, {useMemo} from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import {COLLAPSE_ICON, EXPAND_ICON} from "../util/Util";
import { Input, Icon } from 'semantic-ui-react'

import {useStateWithSessionStorage} from "../util/hooks/useSessionStorage";
import {useRef} from "react/index";

export default function CollapsableCard(props) {
    const {body, header, cardId, filterCallback, ...misc} = props
    // todo instead of creating a new key/value pair for each collapsed card: combine them
    let [isCollapsed, setIsCollapsed] = useStateWithSessionStorage("collapse_card_" + cardId, false);
    let [filter, setFilter] = useStateWithSessionStorage("filter_collapse_card_" + cardId, "");
    let inputRef = useRef();
    let onFilterChange = (value, text, choice) => {
        filterCallback(text.value)
        setFilter(text.value)
    }

    let handleDeleteClick = () => {
        filterCallback("")
        setFilter("")
        inputRef.current.focus()
    }
    let className = useMemo(() => {
        let c = isCollapsed ? "collapsed-card" : "expanded-card"

        if (misc.className) {
            return c + " " + misc.className
        }
        return c
    }, [isCollapsed, misc.className])

    return (
        <Card
            className={className} bg={misc.bg} id={cardId}>
            <Card.Header className={props.headerClass} style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                {header}

                {filterCallback != null && (
                    <>
                        &nbsp;
                    <Input onChange={onFilterChange} placeholder='Filter...' value={filter} ref={inputRef}
                           icon={<Icon name='delete' link onClick={handleDeleteClick}/>}/>
                        &nbsp;
                    </>
                )}
                <Button variant={isCollapsed ? "primary" : "secondary"} onClick={() => setIsCollapsed(!isCollapsed)}>
                    {isCollapsed ? (EXPAND_ICON) : (COLLAPSE_ICON)}
                </Button>
            </Card.Header>
            {isCollapsed ? null : (
                <Card.Body >
                    {body}
                </Card.Body>
            )}
        </Card>
    )
}