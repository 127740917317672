import React from "react";
import {logger} from "./Util";

export default class ErrorBoundary extends React.Component {

    componentDidCatch(error, errorInfo) {
        if (!error) {
            return;
        }
        if (error.status === 401 || error.status === 403) {
            logger("pathname to welcome")
            // noinspection JSIgnoredPromiseFromCall
            window.location.pathname = "/welcome";
        } else {
            throw(error)
        }
    }

    render() {
        return this.props.children;
    }
}