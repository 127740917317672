import {useEffect, useState} from "react";
import {useInterval} from "../../../util/hooks/useInterval";

export default function AlternatingIcons(props) {
    let icons = props.icons;
    let [icon, seticon] = useState(0)

    let [secondsPassed, setSecondsPassed] = useState(0)
    useInterval(() => {
        setSecondsPassed(secondsPassed + 1);
    }, 1000);

    useEffect(() => {
        let newIndex = secondsPassed % icons.length
        seticon(icons[newIndex])
    }, [icons, secondsPassed]) // ignore index, icons

    return icon;
}
