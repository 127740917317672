import {useEffect, useRef} from "react";

export function useInterval(callback, delay) {
    const savedCallback = useRef(callback);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        let id = setInterval(() => {savedCallback.current();}, delay);
        return () => clearInterval(id);
    }, [delay]);
}