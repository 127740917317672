import React, {useCallback, useContext, useMemo, useRef, useState} from "react";
import {ERROR_TOAST} from "../Util";
import {MyToastContext} from "../context/MyToastContext";
import {HttpContext} from "./useHttp";
let Recaptcha = require('react-recaptcha');

export function useMyRecaptcha(props) {
    let httpContext = useContext(HttpContext);
    let toastContext = useContext(MyToastContext);

    let [needsCaptcha, setNeedsCaptcha] = useState(false);
    let [solvedCaptcha, setSolvedCaptcha] = useState(false);
    let recaptchaInstance = useRef();

    let errCallback = useCallback(() => {
        toastContext.addToast("Captcha failed, try again", ERROR_TOAST)
        setSolvedCaptcha(false);
        if (props?.errorCallback) {
            props.errorCallback()
        }
    }, [toastContext])

    let successCallback = props?.successCallback;

    let obj = useMemo(() => {
        return <div>
            <br/>
            <Recaptcha
                ref={recaptchaInstance}H
                sitekey={"6LcdWv4ZAAAAAG2enddoNxq7xWu0r7X1GuHyzDAu"}
                render={"explicit"}
                verifyCallback={(resp) => {
                    //send the response to the backend
                    httpContext.sendRequest("/captcha",
                        {method: "POST", body: JSON.stringify({responseKey: resp})})
                        .then((resp) => {
                            setSolvedCaptcha(true);
                            if (successCallback) {
                                successCallback()
                            }
                        })
                        .catch((resp) => {
                            recaptchaInstance.current.reset();
                            errCallback()
                        })
                }}
                expiredCallback={() => {
                    errCallback()
                }}
            />
        </div>

    }, [httpContext, successCallback, errCallback, solvedCaptcha, needsCaptcha, recaptchaInstance])



    return {
        needsCaptcha: needsCaptcha,
        solvedCaptcha: solvedCaptcha,
        setNeedsCaptcha: setNeedsCaptcha,
        setSolvedCaptcha: setSolvedCaptcha,
        captchaDomObject: obj,
    };

}