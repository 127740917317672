import React from 'react';
// import OverlayTrigger from "react-bootstrap/OverlayTrigger";
// import Tooltip from "react-bootstrap/Tooltip";
// import { Tooltip } from 'react-tooltip'
import {removeSearchParamsFromUrl} from "../util/Util";
import {Popup } from 'semantic-ui-react'


export default function Anchor(props) {
    const childProps = { ...props };

    let useTooltip = props.useTooltip;
    let target = props.target ? props.target : "_blank";

    delete childProps.target;
    delete childProps.useTooltip;

    const anchor = <a {...childProps} target={target} rel={"noopener noreferrer"} onClick={props.onclick}>
        {props.children}
    </a>

    // remove search parameters from the popup display string
    let href = removeSearchParamsFromUrl(props.href)

    return useTooltip ? (
        <Popup position='top center' content={href} trigger={anchor} />
    ) : (
        anchor
    )

}