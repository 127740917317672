import Image from "react-bootstrap/Image";
import reddit_img from "../resources/Reddit_Mark_OnWhite.png";
import Button from "react-bootstrap/Button";
import React from "react";

export default function RedditButton(props) {
    return (
        <Button id={"reddit_connect_button"} onClick={props.clickFunction}>
            {props.text}
            <Image height={30} width={30} src={reddit_img}/>
        </Button>
    )
}