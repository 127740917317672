import React, {useMemo} from 'react';
import Anchor from "../Anchor";
import {isSelfPost} from "./RedditPost";
import useDetectMobileScreen from "../../util/hooks/useDetectMobileScreen";
import {removeSearchParamsFromUrl} from "../../util/Util";

export default function FlairAndTitle(props) {
    let post = props.post
    let {isMobile} = useDetectMobileScreen();

    /**
     * The display name for the url which the reddit post links to
     */
    let linkDisplay = useMemo(() => {
        if (isSelfPost(post)) {
            return `self.${post.subreddit}`
        } else if (post.url) {
            let link = "";
            if (post.url.startsWith("/r/")) {
                return post.url
            }

            if (isMobile) {
                let url = new URL(post.url)
                link = url.hostname
            } else {
                link = removeSearchParamsFromUrl(post.url)
                link = link.replace("https://", "").replace("http://", "").replace("www.", "")
            }
            return link
        }
    }, [post, isMobile])

    let flair = post.flair && !post.flair.startsWith("[") ? `[${post.flair}]` : ""
    return (
        <div style={{marginTop: "1em"}}>
            {/* Flair */}
            {flair && (
                <span className={"reddit_post_flair"}>
                    {flair}
                </span>
            )}
            {/* Title */}
            <b>
                <Anchor className={"reddit_title_link"} href={post.url} >
                    <span className={"title"}>{post.title}</span> <small>({linkDisplay})</small>
                </Anchor>
            </b>
        </div>
    )
}