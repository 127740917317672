import {useEffect, useState} from "react";

export const useGetElementAsync = (query) => {
    const [element, setElement] = useState(null);

    useEffect(() => {
        (async () => {
            let element = await new Promise((resolve) => {
                function getElement() {
                    const element = document.querySelectorAll(query);
                    if (element && element.length > 0) {
                        resolve(element);
                    } else {
                        setTimeout(() => {
                            requestAnimationFrame(getElement);
                        }, 100);
                    }
                }

                getElement();
            });

            setElement(element);
        })();
    }, [query]);

    return element;
};