import Button from "react-bootstrap/Button";
import {FaPlay, FaFastForward, FaPause, FaStepBackward, FaFastBackward, FaStepForward, FaVolumeMute, FaVolumeUp} from "react-icons/fa";
import React, {useContext, useMemo} from "react";
import {MediaQueueWrapperContext} from "./state/MediaQueueWrapper";
import {SpotifyContext} from "./spotify/SpotifyConnect";
import {getUriType, SPOTIFY_ALBUM} from "../../util/Util";

export default function ControlButtons(props) {

    let {spotifyConnect, spotifyState} = useContext(SpotifyContext);
    let queueContext = useContext(MediaQueueWrapperContext);
    let queueData = queueContext.data;

    let play = props.onPlay;
    let next = props.onNext;
    let prev = props.onPrev;
    let mute = props.onMute;

    let isSpotifyAlbum = useMemo(() => {
        return spotifyState.spotifyIsCurrentQueueItem && getUriType(spotifyState.queuedSpotifyUri) === SPOTIFY_ALBUM;
    }, [spotifyState.queuedSpotifyUri, spotifyState.spotifyIsCurrentQueueItem])

    let nextQueue = isSpotifyAlbum ? <FaFastForward/> : <FaStepForward/>
    let prevQueue = isSpotifyAlbum ? <FaFastBackward/> : <FaStepBackward/>
    let nextSpotify = isSpotifyAlbum && <FaStepForward/> ;
    let prevSpotify = isSpotifyAlbum && <FaStepBackward/> ;
    // let prevSpotify = isSpotifyAlbum && spotifyState.currentIndex !== 0 ? <FaStepBackward/> ;
    return (
        <div className={"control_buttons_background"}>

            {/* prev */}
            <Button disabled={queueContext.isFirstItem()} onClick={prev}
                    className={"controlButton"}>
                {prevQueue}
            </Button>

            {/* spotify prev track */}
            {prevSpotify && (
                <Button onClick={spotifyConnect.playPrevTrack} disabled={spotifyState.currentIndex === 0}
                        className={"controlButton"}>
                    {prevSpotify}
                </Button>
            )}

            {/* play / pause */}
            <Button onClick={play} disabled={queueData.isPlayDisabled}
                    className={"controlButton"}>
                {props.isPlaying ? (
                    <FaPause/>
                ) : (
                    <FaPlay/>
                )}
            </Button>


            {/* spotify next track */}
            {nextSpotify && (
                <Button onClick={spotifyConnect.playNextTrack} disabled={spotifyState.isLastIndex}
                        className={"controlButton"}>
                    {nextSpotify}
                </Button>
            )}

            {/* next */}
            <Button disabled={queueContext.isLastItem()} onClick={next}
                    className={"controlButton"}>
                {nextQueue}
            </Button>


            {/*mute / unmute */}
            {!props.hideMute && (
                <Button onClick={mute}
                        variant={props.isMuted ? 'danger' : 'primary'}
                        className={"controlButton"}>
                    {props.isMuted ? (
                        <FaVolumeMute/>
                    ) : (
                        <FaVolumeUp/>
                    )}
                </Button>
            )}
        </div>
    )
}