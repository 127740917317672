import {useCallback, useLayoutEffect} from "react";
import {useLocation, useNavigate} from "@reach/router";

/**
 * Sets the scroll position to the top of the page after reach router navigation
 */
export default function useResetScroll() {

    const navigate = useNavigate()
    const { href, state } = useLocation()

    const updateState = useCallback(() => {
        navigate(href, {
            state: { ...state, scrolled: true },
            replace: true,
        }).then(() => window.scrollTo(0, 0))
    }, [href, state, navigate])

    useLayoutEffect(() => {
        if (!state?.scrolled) {
            updateState()
        }
    }, [state, updateState])
}