import React from 'react';
import {createRoot} from 'react-dom/client';
import './css/index.scss';
import App from './App';
import {Router} from "@reach/router";
import "./dist/bootstrap.min.css"
import "react-bootstrap"
import ErrorBoundary from "./util/ErrorBoundary";
// import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root');
const root = createRoot(container)
let loadedJs = false
window.onSpotifyWebPlaybackSDKReady = () => {
    loadedJs = true
    console.log("abc index.js")
}
root.render(

    <ErrorBoundary>
        <Router style={{width: "100%"}}>
            <App path={"/*"} spotifyLoaded={loadedJs}/>
        </Router>
    </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
