import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {getPercentage, logger} from "../../../util/Util";
import {SpotifyContext} from "./SpotifyConnect";
import useDetectMobileScreen from "../../../util/hooks/useDetectMobileScreen";
import {useInterval} from "../../../util/hooks/useInterval";

/**
 * Progress Bar for spotify
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function MyProgressBar(props) {
    let {spotifyConnect, spotifyState} = useContext(SpotifyContext);
    let {isDesktop} = useDetectMobileScreen();

    let [currentProgress, setCurrentProgress] = useState(0)
    let [currentLabel, setCurrentLabel] = useState("0")
    let barRef = useRef();

    let [secondsPassed, setSecondsPassed] = useState(0)
    useInterval(() => {
        setSecondsPassed(secondsPassed + 1);
    }, 1000);

    let [badTicks, setBadTicks] = useState(0);

    let progressBarLogger = useCallback((...args) => {
        if (false) {
            let dateStr = new Date().toJSON().replace("Z", "").substring(11)

            args[0] = dateStr + ": " + args[0]
            console.log.apply(console, args);
        }
    }, [])

    useEffect(() => {
        // if (badTicks === 1 || (badTicks > 0 && badTicks % 5 === 0)) {
        if (badTicks > 0 && badTicks % 5 === 0) {
            progressBarLogger("bad ticks", badTicks, "forcing update")
            spotifyConnect.updatePlayerState();
        }
    }, [progressBarLogger, badTicks, spotifyConnect, spotifyConnect.api, spotifyConnect.updatePlayerState])

    /**
     *  Re-calculate progress every second
     */
    useEffect(() => {
        // if the last update is 'paused': use the position data they give
        let position = spotifyState.playerState?.getProgress() || 0
        let duration = spotifyState.playerState?.getDuration() || 0

        // if the last update is 'playing': use the position data they give + elapsed time since the update
        if (position !== undefined && spotifyState && spotifyState.lastUpdateTime && !spotifyState.playerState.isPaused()) {
            let timePassed = (Date.now() - spotifyState.lastUpdateTime) / 1000;
            progressBarLogger("\tseconds since last update: ", timePassed)
            if (timePassed > .6) {
                let origPosition = position
                position = position + timePassed;
                progressBarLogger("\tUsing timer to guess progress. orig:", origPosition, "best guess: ", position)
                progressBarLogger("bad ticks++", badTicks+1)
                setBadTicks(badTicks+1)
            } else {
                progressBarLogger("bad ticks", 0)
                setBadTicks(0)
            }
        }

        let prog = getPercentage(position, duration)
        if (prog.percent) {
            if ((prog.percent && !currentProgress) || (Math.abs(prog.percent - currentProgress) >= 1)) {
                // only update if a second has gone by
                progressBarLogger(`percent done: ${prog.percent} [${position} / ${duration}]`)
                setCurrentProgress(prog.percent)
            }
        } else {
            if (currentProgress && currentProgress > 0) {
                setCurrentProgress(0)
            }
        }
        // don't update unless it changed
        if (prog.label !== currentLabel) {
            progressBarLogger("Updating label: ", prog.label)
            setCurrentLabel(prog.label)
        }
    }, [secondsPassed, currentProgress, spotifyState, currentLabel, progressBarLogger, secondsPassed, spotifyConnect.lastUpdateTime, spotifyState.playerState])
    // ignore badTicks. force include secondsPassed

    /**
     * This is called on mobile
     */
    let onChangeEvent = useCallback((ev) => {
        let durationMs = spotifyState?.playerState?.getDurationMs()
        if (durationMs) {
            let ratio = Number(ev.currentTarget.value) / 100
            logger("\tsetTrackPosition. Percent: ", ratio * 100)
            let newProgress = durationMs * ratio
            spotifyConnect.seek(newProgress)
            spotifyConnect.updatePlayerState()
        }
    }, [spotifyConnect, spotifyState?.playerState])

    /**
     * This is called on desktop.
     */
    let clickEvent = useCallback(function (ev) {
        let rightBound = barRef.current.getBoundingClientRect().right
        let leftBound = barRef.current.getBoundingClientRect().left
        let clickLocation = ev.screenX || ev.currentTarget.getBoundingClientRect().x;
        logger("right: ", rightBound, "left: ", leftBound, "click: ", clickLocation)
        let distance = rightBound - leftBound
        let ratio = (clickLocation - leftBound) / distance
        logger("\tpercent: ", ratio*100)

        let durationMs = spotifyState?.playerState?.getDurationMs()
        if (durationMs) {
            let newProgress = durationMs * ratio
            logger("new location: " + (newProgress) + ". Song length: " + (durationMs))
            spotifyConnect.seek(newProgress)
            spotifyConnect.updatePlayerState()
        } else {
            logger("\t ignoring click event")
        }

    }, [spotifyConnect, spotifyState?.playerState])

    return (
        <Row style={{flexDirection: "column"}}>

            <Col xs={12} onClick={clickEvent} className={"white_text progressBarBox"}>
                    <div style={{flex: "1 1 20%", width: "100%", padding: "0 1rem" }}>
                        {currentLabel}
                    </div>
                    <div style={{flex: "1 1 80%", width: "100%", padding: "0 1rem" }}>
                        <input
                            ref={barRef}
                            id='range'
                            type='range'
                            min='0'
                            max='100'
                            value={currentProgress}
                            style={{ width: 300 }}
                            onChange={onChangeEvent}
                        />
                    </div>
                {/*<progress max={100} value={progressPercent} />*/}
            </Col>
        </Row>
    )
}

